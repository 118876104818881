.purchase_main {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 15px;
}

.purchase_main .small_p {
    font-size: 12px;
    margin-bottom: 6px;
}

.purchase_main .purchase_left h3 {
    font-size: 20px;
    font-weight: 700;
}

.purchase_main .purchase_right h3 {
    font-size: 20px;
    font-weight: 700;
}

.purchase_main .purchase_right h3 span {
    font-weight: 400;
}

.devided_hr {
    margin: 20px 0 !important;
    opacity: 0.4;
}

.purchase_card {
    border-radius: 8px;
    overflow: hidden;

}

.Purchase_modal .purchase_header {
    font-size: 11px;
    padding: 8px;
}

.purchase_pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px;
    gap: 12px;
}

.purchase_pay .flex_img {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 12px;
    word-break: break-word;
}
.purchase_pay .flex_img img{
    width: 35px;
    height: 24px;
}
.purchase_pay button {
    padding:8px 16px;
    white-space: nowrap;
    min-width: unset;
    font-weight: 500;
    font-size: 14px;
    border-radius: 16px;
    letter-spacing: 0px;
    text-transform: none;
}
.modal_bottom p{
    margin-top: 20px;
    font-size: 13px;
}
.modal_bottom p a{
  color: #fff;
}
@media (max-width: 768px) {
    .modal_bottom p{
        font-size: 12px;
        margin-top: 30px;
    }
    .purchase_main .purchase_left h3 , .purchase_main .purchase_right h3 {
        font-size: 18px;
    }



}
