:root {
    --primaryFont: "Open Sans", system-ui;
}

html,
body,h1,h2,h3,h4,h5,h6,label,input {
    font-family: var(--primaryFont) !important;
}

.login_page {
    text-align: center;
}

.login_page h3 {

    font-size: 22px;
    font-weight: 700;
    line-height: 29.96px;
    margin: 1rem 0;
    margin-bottom: 0.5rem;
}

.login_page p {
    color: #C6C6C6;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;

}

.login_page p.Mui-error {
    font-size: 14px;
    margin-left: 10px;
}

.flex-btn {
    display: flex;
    gap: 20px;
    justify-content: center;
    /* margin-top: 3rem; */
    margin-top: 5px;
}

.flex-btn a {
    border: 1px solid #606060;
    text-decoration: none;
    width: 237px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    font-size: 22px;
    font-weight: 700;
    line-height: 29.96px;
    color: #fff;
}

.flex-btn a svg {
    font-size: 2rem;
    margin-right: 0.5rem;
}

.flex-btn a:first-child svg {
    color: #1877f2;
}

.bar_text p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
    margin-bottom: 0;
    z-index: 1;
    display: inline-block;
    padding: 0 2rem;
}

.bar_text {
    position: relative;
    /* padding: 2rem 0; */
    margin-top: 5px;
    padding-bottom: 5px;
}

.bar_text::after,
.bar_text::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    display: block;
    width: 15%;
    height: 0.5px;
    background-color: #ffff;
    margin-top: 10px;
    z-index: -1;
}

.bar_text::after {
    right: 4%;
    left: auto;
}

.login_page input[type="text"],
.login_page input[type="email"],
.login_page input[type="password"] {
    width: 100%;
    padding: 10px;
    height: 46px;
    background: #323232;
    border-radius: 7px;

    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
    text-align: left;


}

.login_page button {
    background: #03B449 !important;
    border: 1px solid #fff;
    border-radius: 8px;
    max-width: 354px;
    height: 64px;
    width: 100%;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 29.96px;
    text-align: left;
    opacity: 1 !important;
    text-transform: none;
}

input::placeholder {
    color: #A6A6A6;
}

.login_page form>div {
    /* margin-bottom: 1rem; */
    margin-bottom: 5px;
}

.login_page .remember_div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 2rem;
}

.remember_div .form-check {
    display: flex;
    align-items: center;
}
.remember_div .form-check  label{
    color: #A6A6A6;
    margin-left: 10px;
}

.remember_div svg {
    color: #9A9A9ACC;
    font-size: 18px;
}

.login_page p a {
    color: #fff;
}

.forget_p {
    margin-top: 2rem;
    /* margin-top: 20px!important; */
}

.form_fields{
    margin-top: 1.5rem;
}

.forget_p a {
    text-decoration: none;
}

.sign_p a {
    font-weight: 700;
    color: #367AFF !important;
}

.pass_div {
    position: relative;
}

.pass_div svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
}

.pass_div input {
    padding-right: 60px !important;
}
.condition_check{
    margin-bottom: 2rem;
}

.condition_check .form-check{
  display: flex;
  gap: 1rem;
  text-align: left;
  align-items: start;
  margin-top: 0.5rem;
}
.condition_check .form-check label{
    font-size: 14px;
}
.condition_check .form-check label a{
    color: #1A77F2;
}
.thanks_div{
    margin-top: 8rem;
}
.thanks_div h2{
    color:#49C0E9;

font-size: 40px;
font-weight: 700;
line-height: 54.47px;

}
.login_page form .space_div{
    margin: 3rem 0;
    margin-bottom: 3rem;
}
.forget_div{
    margin-bottom: 2rem !important;
    margin-top: 6rem;
}
.login_p svg{
    font-size: 25px;
}
.login_p{
    margin-bottom: 2rem;
}
.login_p a{
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}
.reset_div{
    display: flex;
    gap: 1.5rem;
    align-items: center;
}
.login_page .reset_div{
    margin-top: 3rem;
    margin-bottom: 4rem;
}

/*  */
.login_page .flex-btn a:first-child svg{
    color: #1877f2;
}
.form_logout{
    /* padding: 4rem 0; */
    padding-top: 10px;
}
.form_logout input{
    border: none;
}
.form_logout  .css-9425fu-MuiOutlinedInput-notchedOutline{
  border: none;
}
.form_logout h6{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 18px;
}
.eye_icon{
    position: relative;
}
.eye_icon svg{
  color: #9A9A9ACC;
  position: absolute;
   right: 2rem;
   top: 50%;
   transform: translateY(-50%);
}
.eye_icon svg .Mui-error{
    top: 30%;
}
.form_logout .MuiStepper-horizontal{
 margin: 1rem 0rem 2rem 0rem;
}
.form_logout .css-m8a1uf-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    width: 50px;
height: 50px;
background: #ED1D51;
color: transparent !important;
  border-radius: 50%;
}
 /* //justr for testing purpose */
.form_logout .css-jepbcm.Mui-active {

    width: 50px;
    height: 50px;
    background: #ED1D51;
    color: transparent !important;
    border-radius: 50%;
    margin-top: -10px;
}

.form_logout .css-1abj2s5.Mui-disabled{
    fill: #0A0A0A !important
}

.form_logout .css-jepbcm{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: -10px;
}

.form_logout .css-jepbcm.Mui-completed {

    width: 50px;
    height: 50px;
    /* background: #ED1D51; */
    color: transparent !important;
    border-radius: 50%;
    margin-top: -10px;
}

.form_logout .css-opt7yd-MuiStepIcon-text{
  fill: #fff !important;
}
.form_logout .css-m8a1uf-MuiSvgIcon-root-MuiStepIcon-root{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.form_logout .css-zpcwqm-MuiStepConnector-root{
  top: 25px;
}
.form_logout .css-g7zrp6-MuiStepConnector-line{
  border-color: #ED1D51;
}
.form_logout .Mui-disabled.MuiStepLabel-alternativeLabel .css-m8a1uf-MuiSvgIcon-root-MuiStepIcon-root{
    fill: #0A0A0A !important;
    border: 1px solid #fff;
}
.form_logout .css-m8a1uf-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color: #ED1D51;
}
.form_logout .css-11vmtxu-MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    font-size: 16px;
}
.form_logout input[type="checkbox"]:checked{
    accent-color: #ed1d51;
}
.condition_check{
    margin-top: 1rem;
}
.signup_page  .MuiGrid-grid-md-3 h6,.signup_page  .MuiGrid-grid-md-6 h6{
  font-size: 14px;
  margin-top: 0;

}
.congrat_page button, .new_forget_page button{
 margin-top: 3rem;
 margin-bottom: 1rem;
}
.congrat_page .sign_p{
color: #FFFFFF;
font-size: 22px;
font-weight: 700;
line-height: 29.96px;

}
.new_forget_page .forget_div{
 margin-top: 4rem;
}
.new_forget_page h6{
    margin-top: 1rem;
}
.reset_page div img{
  margin-top: 150px;
}
.reset_page p{
    color: #fff;
}
.reset_page button{
    margin-top: 2.5rem;
}
.otp_page button {
    margin-top: 4rem;
}
.otp_page .sign_p a{
 font-weight: 400;
}
.otp_page .sign_p{
    margin-top: 2rem;
}
.otp_page img{
    margin-bottom: 1rem;
}

@media (max-width:767px) {
    .login_page h3{
    font-size: 20px;
    }
    .login_page p{
        font-size: 14px;
        margin-top: 0;
    }
    .bar_text::after, .bar_text::before,
    .signup_page .bar_text::after, .signup_page .bar_text::before{
        margin-top: 1px;
    }
    .login_port .MuiGrid-grid-md-5{
      width: 90%;
    }
    .login_page  .flex-btn{
        margin-top: 1.5rem;
    }
    .bar_text{
        padding: 1rem 0;
    }
    .form_logout h6,.signup_page .bar_text p{
        font-size: 14px;
        font-weight: 400;
        margin-top: 0;
        margin-left: 0 !important;
    }
    .login_page input[type="text"], .login_page input[type="email"], .login_page input[type="password"]{
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        padding-left: 1rem;
        border-radius: 4px !important;
    }
    .login_page .remember_div{
        margin-bottom: 3rem;
    }
    .login_page button{
        font-size: 14px;
        font-weight: 700;
        height: 40px;
        width: 220px;
        margin-bottom: 1rem;
        border-radius: 4px !important;
    }
.forget_p{
    margin-bottom: 0.5rem;
}
.eye_icon svg{
    font-size: 1rem;
}
.thanks_div {
    margin-top: 3rem;
}
.thanks_div img{
    height: 61px;
}
.logo{
    height: 39px;
}
.form_logout {
    padding: 2rem 0;
}
.thanks_div h2{
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin: 0.5rem;
}
.congrat_page button, .new_forget_page button{
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.congrat_page .sign_p{
    font-size: 14px;
    font-weight: 700;
}
.login_p{
    margin-top: 1.5rem !important;
}
.forget_div{
    margin-top: 4rem;
    margin-bottom: 3rem !important;
}
.otp_page input[type="text"]{
    height: 48px;
    font-size: 26px !important;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center !important;
    min-width: 36px;
}
.new_forget_page .forget_div {
    margin-top: 2rem;
    margin-bottom: 2rem !important;
}
.new_forget_page .forget_div h6 {
    margin-top: 10px;
}
.new_forget_page   .login_p{
  margin-top: 0 !important;
}
.reset_page div img {
    margin-top: 50px;
    height: 82px;

}
.reset_page button{
    margin-top: 1.5rem;
}
}

.spinsaga_logo{

    height: 50px!important;
}

.top_buttons button{
    background: #03B449!important;
    color:white;
    text-transform: capitalize;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
}