:root {
  --primaryFont: "Open Sans", system-ui;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
ul li span {
  font-family: var(--primaryFont) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

header .web-header {
  min-height: 86px;
}

.header-logo {
  margin-left: 2rem;
  cursor: pointer;
}

.header_search {
  margin-left: 100px !important;
}

.header_search {
  border-radius: 31px;

}

.header_search .MuiOutlinedInput-root {
  width: 600px;
  height: 46.04px;
  gap: 0px;
  border-radius: 31px;
}

.header_search_mobile {
  margin-right: 10px !important;
}

.header_search_mobile {
  border-radius: 31px;

}

.header_search_mobile .MuiOutlinedInput-root {
  width: 350px;
  height: 46.04px;
  gap: 0px;
  border-radius: 31px;
}

.inner-body .normal_p {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  margin-bottom: 1rem;
}

.big-img img {
  border-radius: 14px;
}

.big-img,
.big-img button {
  background: transparent !important;
  background-image: linear-gradient(rgb(255 255 255 / 0%),
      rgb(255 255 255 / 0%)) !important;
  height: 200px;
}

.heading_button,
.icon_heading {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.heading_button p {
  font-weight: 700;
}

.icon_heading {
  justify-content: flex-start;
}

.icon_heading p {
  font-weight: 700;
}

.heading_button button {
  font-size: 14px;
  font-weight: 400;
  padding: 0.45rem 1.8rem;
}

.inner-body .abs_graident {
  text-align: center;
  background: rgb(251, 177, 28);
  background: linear-gradient(90deg,
      rgba(251, 177, 28, 1) 0%,
      rgba(248, 77, 5, 1) 51%);
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem;
}

.price_box {
  width: 80%;
  margin: 0 auto;
  background: #07a537;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  margin-top: 0.8rem;
}

.price_box p {
  margin: 0;
  font-size: 12px;
  color: #000;
}

.price_box h3 {
  margin: 0;
  font-weight: 600;
}

.buy_card .MuiCard-root {
  padding-bottom: 1rem;
}

.header-logo {
  height: 40px;
}

.btn_group {
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem !important;
  display: flex;
  gap: 0.6rem;
}

.btn_group button {
  border-radius: 30.66px;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  min-width: max-content;
  border-width: 1px;
  border-style: solid;
}

.btn_group button svg {
  font-size: 18px;
}

.btn_group button.active {
  background: rgb(237, 29, 81);
  background: linear-gradient(180deg,
      rgba(237, 29, 81, 1) 70%,
      rgba(135, 17, 46, 1) 100%);
  font-weight: 700;
  color: #fff !important;
}

.mt-5 {
  margin-top: 2rem;
}

.radius-none img {
  border-radius: 0;
}

.inner-body .c_card {
  padding: 2rem 1.5rem;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  overflow: visible;
  margin-top: 1rem;
  height: 100%;
}

.inner-body .f18 {
  font-size: 18px;
}

.inner-body .f-bold {
  font-weight: 700;
}

.half_flex {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.left_half {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.c_card .f18 {
  margin-bottom: 12px;
}

.c_card button {
  font-size: 14px;
  /* discuss with designer */
  font-weight: 700;
  padding: 0.1rem 1.5rem;
}

.c_card .margin-4 {
  margin-top: -12px;
}

.offer-box {
  position: absolute;
  top: -15px;
  left: 50px;
  border-radius: 12.53px;
  padding: 0.1rem 1rem;
}

.right_half {
  flex-basis: 117px;
}

.c_card .red_btn {
  margin-bottom: 1rem;
}

.inner-body .buy_row {
  /* margin-bottom: 5rem; */
}

.inner-body .buy_footer {
  text-align: center;
  flex-direction: column;
}

.inner-body .buy_footer p {
  display: block;
  text-align: center;
  margin: 3rem 0;
}

.buy_footer button {
  width: 244.56px;
  height: 54.18px;
  gap: 0px;
  border-radius: 40.45px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  margin-top: 4rem;
}

.profile_footer button {
  margin-top: 3rem !important;
}

.redeem_card p {
  margin-bottom: 10px;
}

.inner-body .heading-p {
  font-size: 20px;
  font-weight: 700;
  line-height: 27.24px;
}

.inner-body .redeem_card {
  padding: 15px;
  border-radius: 10px;
}

.inner-body .heading-h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 29.96px;
}

.redeem_card .mt-5 {
  margin-top: 3rem;
}

.inner-body .space_4 {
  margin-top: 0.5rem;
}

.gift_card {
  border-radius: 10px;
  text-align: center;
}

.gift_card .gift_img {
  width: 128px;
  height: 128px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
}

.color_body {
  padding: 1rem;
}

.gift_card h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 27.24px;
}

.gift_card button {
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 12px;
}

.redeem_content {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.redeem_content p,
.redeem_content ul li span {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.redeem_content ul,
.redeem_content ul li {
  padding: 0;
  margin: 0;
}

.redeem_content ul li {
  padding-left: 10px;
}

.inner-body .redeem_footer {
  margin: 3rem 0;
}

.sidebar {
  background-image: url("./images/blur-vertical.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 10px;
  padding: 25px 15px 25px 20px;
}

.red_gradient {
  background: rgb(237, 29, 81);
  background: linear-gradient(90deg,
      rgba(237, 29, 81, 1) 50%,
      rgba(150, 16, 49, 1) 100%);
}

.inner-main {
  display: flex;
  gap: 0.5rem;
  height: 75px;
  overflow: hidden;
  width: 100%;
}

.inner_right {
  font-size: 14px;
  font-weight: 700;
  padding-top: 6px;
}

.inner_right p {
  margin-bottom: 0px;
  color: #fff;
}

.inner_left {
  width: 70px;
}

.inner_right p+p {
  margin-top: 0;
  margin-bottom: 0;
}

.inner_right .MuiLinearProgress-root {
  margin-top: 6px;
}

.sidebar .side_box {
  border-radius: 8px;
  margin-top: 12px;
}

.yellow_gradient {
  background: rgb(227, 124, 3);
  background: linear-gradient(90deg,
      rgba(227, 124, 3, 1) 50%,
      rgba(234, 155, 0, 1) 100%);
}

.yellow_gradient img,
.blue_gradient img {
  margin-bottom: -56px;
}

.blue_gradient {
  background: rgb(26, 119, 242);
  background: linear-gradient(90deg,
      rgba(26, 119, 242, 1) 50%,
      rgba(3, 61, 160, 1) 100%);
}

.green_gradient {
  background: linear-gradient(180deg, #00C300 0%, #005D00 100%);

}

button.green_gradient {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.green_gradient_close {
  background: linear-gradient(180deg, #00C300 0%, #005D00 100%);

}

button.green_gradient_close {
  /* discuss with desginer */
  font-weight: 700;
  color: #fff;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid !important;
}

.f-16-bold,
.side_list ul li span {
  /* font-size: 16px !important;
  font-weight: 700 !important; */
  font-size: 14px !important;
  font-weight: 500 !important;
}

.f-16-bold,
.side_list ul li span .active {
  font-weight: bold !important;
}

.side_list {
  margin-top: 12px;
  /* padding-top: 1rem; */
  /* border-top: 1px solid #616161; */
  cursor: pointer;
}

.side_list .MuiListItemAvatar-root {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side_list ul li {
  gap: 25px;
  transition: all 0.2s;
  border-radius: 4px;
}

.side_list .active .border-1 {
  background: #ed1d51;
  color: #fff;
}

/* main .side_list + .side_list {
  margin-top: 1.4rem;
} */
.dark_light_btn {
  border-radius: 46px;
  padding: 5px;
}

main .dark_light_btn {
  margin-top: 2rem;
}

.dark_light_btn button {
  width: 111.33px;
  height: 51.85px;
  gap: 0px;
  border-radius: 46px;
  font-size: 14px;
  font-weight: 700;
}

.dark_light_btn {
  display: flex;
  gap: 5px;
}

.dark_light_btn button svg {
  margin-right: 6px;
}

.c_scroll::-webkit-scrollbar-track,
.c_scroll .MuiDrawer-paperAnchorLeft::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.c_scroll::-webkit-scrollbar,
.c_scroll .MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.c_scroll::-webkit-scrollbar-thumb,
.c_scroll .MuiDrawer-paperAnchorLeft::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #474747;
}

.hide_scroll.c_scroll::-webkit-scrollbar,
.hide_scroll.c_scroll .MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
  width: 0;
}

/* //new design anf issue occuer for the center content discus with desingmer */
.button_scroll .c_scroll {
  width: 100%;
  overflow-x: auto;
}

.button_scroll .c_scroll::-webkit-scrollbar {
  height: 6px;
}

.favorites {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}

.header_dropdown .MuiInputBase-formControl {
  width: 250px;
  height: 47.58px;
  border-radius: 31px 0px 0px 31px;

}

.header_dropdown {
  border-radius: 0 31px 31px 0;
}

.coin_select {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.header_dropdown .MuiSelect-select {

  padding-left: 60px;
}

.header_dropdown {
  margin-left: 20px !important;
}

.header_dropdown {
  display: flex !important;
  gap: 0;
  flex-direction: row !important;
}

.add_header {
  width: 74px;
  height: 47.58px;
  border-radius: 0px 31px 31px 0px !important;
  font-size: 1.2rem !important;
}

.m-auto {
  margin-left: auto;
}

.header_bar {
  padding-top: 0 !important;
  background: transparent !important;
}

.header_group_icon {
  display: flex;
  gap: 1.5rem;
}

.header_group_icon button {
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}

.header_group_icon button:hover {
  background-color: none !important;
}

.user_header {
  position: relative;
}

.user_abs_header {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 7px !important;
}

.collapse_sidebar .sidebar .side_box {
  width: 125px;
  height: 47px;
}

.collapse_sidebar .side_list ul li {
  justify-content: center;
}

.collapse_sidebar main .dark_light_btn {
  flex-wrap: wrap;
}

.collapse_sidebar .dark_light_btn {
  border-radius: 32px;
}

.custom_buy_card {
  position: relative;
}

.custom_buy_card p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2px;
  color: #080808;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  font-size: 10px;

}

.buy_conin_div {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 42%;
  text-align: center;
}

.buy_conin_div h3 {
  margin: 0;
  color: #fff;
  font-size: 30px;
  line-height: 30px;
}

.buy_conin_div h4 {
  margin: 0;
  color: #fff;
  font-size: 20px;

}

.custom_buy_card h2 {
  position: absolute;
  color: #fff;
  font-size: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-transform: uppercase;
  top: 58%;
  text-align: center;
}

.custom_buy_card .price_box p {
  position: relative;
  font-size: 12px;
  letter-spacing: 0;
  text-decoration: line-through;
}

.custom_buy_card .price_box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  bottom: 25px;
  background: transparent;
  /* bottom: 15px; */
}


@media (min-width: 1228px) and (max-width: 1400px) {

  .custom_buy_card h2 {
    position: absolute;
    color: #fff;
    font-size: 22px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-transform: uppercase;
    top: 57%;
    text-align: center;
  }

  .custom_buy_card .price_box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    bottom: 20px;
    background: transparent;
    /* bottom: 15px; */
  }

  .buy_conin_div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    top: 42%;
    text-align: center;
  }

  .buy_conin_div h3 {
    margin: 0;
    color: #fff;
    font-size: 25px;
    line-height: 30px;
  }

  .buy_conin_div h4 {
    margin: 0;
    color: #fff;
    font-size: 17px;

  }
}

.game_modal .MuiDialog-paper {
  background: linear-gradient(170.89deg, #FF0040 6.91%, #870626 95.9%);
  border: 1px solid #fff;
  border-radius: 11px;
}

.game_image {
  background: linear-gradient(170.89deg, #FF0040 6.91%, #870626 95.9%);
}

.game_modal .MuiDialog-paper h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 19.07px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 1.6rem;
  padding-bottom: 1.2rem;
  color: #fff;
}

.game_modal .MuiDialog-paper p,
.price_box h3 {
  color: #fff;
}

.game_modal .MuiDialog-paper .MuiCardMedia-img {
  width: 522px;
  height: 252px;
  object-fit: fill;

}

.space_t {
  margin-top: 1rem !important;
}

#modal_purchase button {
  /* width: 150px;
  height: 30px; */
}

.game_modal .MuiDialog-paper .info_game_modal h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 16.34px;
  color: #fff;
  text-align: center;
}

.game_modal .MuiDialog-paper .info_game_modal h3 p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: center;
}

.custom_flex {
  display: flex;
  flex-wrap: wrap;
}

.flex_half {
  width: 50%;
  text-align: center;
}

.info_game_modal h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  margin-bottom: 6px;
}

.info_game_modal p {

  font-size: 14px;
  font-weight: 400;
  line-height: 16.34px;

}

.game_modal .MuiDialog-paper .modal_game_modal {
  width: 100%;
  background: #03B449;
  color: #fff;
  font-family: Open Sans Hebrew;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  box-shadow: 0px 2px 2px 0px #0000001A;
  border-radius: 5px;
  padding: 0.7rem;
}

h2.title_heading,
.profile_card h4 {
  margin: 2rem 0;
  font-size: 20px !important;
  font-weight: 700;
  line-height: 27.24px;

}

.form_profile .css-1pfjluw-MuiTypography-root {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.51px;
  margin-bottom: 12px;
  margin-top: 0.5rem;
  font-family: var(--primaryFont) !important;

}

.form_profile input {
  border-radius: 7px;
  padding: 11px 14px;
  font-family: var(--primaryFont) !important;

}

.form_profile .profile_card input {
  background: #fff;
  color: #828282
}

.form_profile .profile_card input::placeholder {
  color: #82828280
}

.form_profile .MuiInputBase-fullWidth {
  border-radius: 7px;
}

.profile_card {
  border-radius: 10px !important;
  padding: 1.5rem;
}

.profile_card h4 {
  margin: 0.5rem 0;
}

.profile_card .form_p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  margin-bottom: 2.5rem;
}

.profile_card .forget_div {
  margin-top: 0;
}

.form_profile .profile_card button {
  min-width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  height: 45px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  outline: none;
  border: none;
  text-transform: none;
}

body .f18_700,
.form_profile p span,
p.f18_700 span {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;

}

.inner_space {
  padding: 1.5rem;
  /* padding-top: 3rem; */
}

.table_design h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 38.13px;

}

.table_design h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;

}

.from_flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.from_flex .css-f1eluj-MuiInputBase-root-MuiOutlinedInput-root,
.from_flex .css-jdbs2a-MuiInputBase-root-MuiOutlinedInput-root {
  height: 36px;
}

.from_flex .css-f1eluj-MuiInputBase-root-MuiOutlinedInput-root .css-i4bv87-MuiSvgIcon-root,
.from_flex button .css-i4bv87-MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
}

.table_custom {
  margin-top: 3rem;
  border: 1px solid #2E2D2D;
}

.table_custom thead th {
  /* background:  #262626; */
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: center;

}

.table_custom tbody td {
  /* background: #131313;
  border-color: #2E2D2D; */
  font-size: 16px;
  font-weight: 400;
  text-align: center;

}

.top_space {
  padding-top: 5rem;
}

.p_space {
  margin: 2rem 0;
}

.b_space {
  margin-bottom: 3rem;
}

.content_page h2,
.content_page p b {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;

}

.content_page li a {
  color: #fff;
}

.content_page {
  padding: 3rem 1.5rem;
}

.w_95 {
  width: 95%;
}

.mobile_fix_bottom {
  display: none;

}

.buy_card1 .right_half {
  width: 50%;
  margin: 0 auto;
}

.long-card {
  border-width: 0 !important;
}

.inner-body .long-card {
  padding: 7px 12px 7px 56px;
}

.inner-body .long-card button {
  min-width: 192px;
  padding: 10px 37.5px;
  font-size: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: 500;
}

.long-card {
  position: relative;
}

.long_img_coin img {
  position: absolute;
}

.big_coin {
  top: -17px;
  left: 0;
}

.small_coin {
  top: 47px;
  left: 0;

}

.back_coin {
  top: -11.25px;
  left: 78px;
  z-index: -1;

}

/* mobile view css */
@media (max-width:767px) {

  .header_search,
  .add_header {
    display: none !important;
  }

  .header_dropdown .MuiInputBase-formControl {
    border-radius: 31px;
    width: 112px;
    height: 26px;
  }

  header .header_dropdown .MuiSelect-outlined {
    padding: 0.5rem !important;
    font-size: 10px;
    text-align: center;
  }

  .collapse_sidebar,
  .user_abs_header {
    display: none;
  }

  #main_sidebar {
    margin-left: 0 !important;
    width: 100%;
  }

  .header_group_icon button:last-child {
    display: none;
  }

  header .MuiTypography-h6 {
    order: 3;
    margin: 0 auto;
  }

  header .header_dropdown {
    order: 4;
  }

  header .header_group_icon {
    order: 2;
  }

  .header_group_icon button {
    min-width: 31px;
    height: 31px;
    font-size: 0.8rem;
    background-color: none;
  }

  header .web-header {
    min-height: 60px;
  }

  .header-logo {
    height: 30px;
  }

  .coin_select {
    height: 11px;
  }

  header .web-header .MuiIconButton-colorInherit {
    padding-bottom: 0;
  }

  .custom_buy_card p {
    font-size: 6px;
  }

  .buy_conin_div h3 {
    font-size: 14px;
    line-height: 15px;
  }

  .buy_conin_div h4 {
    font-size: 10px;
  }

  .custom_buy_card h2 {
    font-size: 14px;
  }

  .price_box h3 {
    font-size: 12px;
  }

  .custom_buy_card .price_box {
    bottom: 2px;
  }

  .custom_buy_card .price_box p {
    font-size: 8px;
  }

  .mobile_fix_bottom {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 99;
  }

  .bottom_bar {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }

  .bottom_bar ul {
    display: flex;
    padding: 0;
    align-items: center;
  }

  .bottom_bar ul li {
    display: block;
    border-right: 1px solid;
    text-align: center;
    padding: 0.5rem;
  }

  .bottom_bar ul li:nth-child(3) {
    padding: 0;
    border: 0;
  }

  .bottom_bar ul li:nth-child(2) {
    border: 0;
  }

  .bottom_bar ul li:last-child {
    border-right: 0;
  }

  .bottom_bar button.green_gradient_close {
    flex-wrap: wrap;
  }

  .bottom_bar ul li span {
    font-size: 10px;
  }

  .game_modal .MuiDialog-paper h2,
  .form_profile .css-1pfjluw-MuiTypography-root,
  body .f18_700,
  .form_profile p span,
  p.f18_700 span,
  h2.title_heading,
  .profile_card h4,
  .icon_heading p,
  .inner-body .f18,
  .c_card button,
  .buy_footer button,
  .redeem_card p {
    font-size: 14px !important;
  }

  .inner-body .heading-p,
  .inner-body .heading-h2,
  .content-page-design h2 {
    font-size: 18px !important;
  }

  .content-page-design p,
  .content-page-design ul li {
    font-size: 14px;
    font-weight: 400;
  }

  .gift_card h5 {
    font-size: 16px;
  }

  .buy_footer button {
    width: 214.03px;
    height: 47.6px;
    margin-top: 2rem;
  }

  .inner-body .buy_footer p,
  .redeem_main .f18_700 {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  h2.title_heading,
  .profile_card h4 {
    margin: 0.5rem 0;
  }

  .game_modal .css-ndaqpv-MuiTypography-root,
  .form_profile input,
  .gift_card button {
    font-size: 12px;
  }

  .gift_card .mt-5,
  .redeem_content p,
  .redeem_content ul li span {
    font-size: 10px;
    font-weight: 400;
  }

  .redeem_content ul li span {
    font-weight: 700;
  }

  .inner-body .buy_footer p,
  .redeem_main .f18_700,
  .redeem_main .f18_700 span {
    font-size: 12px !important;
  }

  .game_modal .MuiDialog-paper .MuiCardMedia-img {
    width: 342px;
    height: 165px;

  }

  .game_modal .space_t {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .form_profile {
    padding: 0 !important;
    padding-bottom: 2rem !important;
  }

  .inner_space {
    padding: 0;
  }

  .big-img,
  .big-img button {
    height: 100px;
  }

  .c_card button {
    padding: 0.4rem 0.8rem;
  }

  .c_card button br {
    display: none;
  }

  .top_space {
    padding-top: 0.5rem;
  }

  .redeem_card .mt-5 {
    margin-top: 2rem;
  }

  .custom_buy_card>.goldcoin_home {

    font-size: 12px;
  }

  .limit_btn.MuiButton-root {
    font-size: 12px !important;
  }

  .quickbuy_btn.MuiButton-root {
    font-size: 12px !important;
  }
}

.sidebar-coin {

  border-radius: 10px;
}

.no_hover:hover {

  background: #00C300 !important;
  ;

}

.black_box {
  padding: 16px;
  border-radius: 8px;
}

.black_box .main_flex,
.flex_between {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.black_box .main_flex h2 {
  display: flex;
  gap: 0.5rem;
  font-size: 16px;
  align-items: center;
  font-weight: 500;
}

.black_box .main_flex h2 img {
  height: 15px;
}

.black_box .main_flex h2 img.redeemsaga_coin {
  height: 19px !important;
  margin-left: -3px;
}

.black_box .inner-main {
  height: 40px;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 30px !important;
  /* margin-top: 1.5rem; */
}

.black_box .inner-main:hover {
  backdrop-filter: brightness(0%);
  filter: brightness(90%);
}

.side_list ul li:hover {
  background: #2d2d2d;
}

.first_modal {
  text-align: center;
}

.first_modal h2 {
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  padding-top: 2rem;
}

.first_modal h5 {
  font-size: 16px;
  font-weight: 400;
}

.first_modal h6 {
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.flex_div {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.first_modal .flex_div {
  justify-content: center;
}

.first_modal img {
  width: 100%;
}

.first_modal .flex_div h3 {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
}

.first_modal .flex_div h3 img {
  height: 20px;
  width: auto;
}

.opacity_half {
  opacity: 0.5;
}

.first_modal .full_btn {
  text-transform: none;
  font-weight: 700;
  width: 100%;
  display: inline-block;
  padding: 12px 10px;
  padding-bottom: 15px;
  border-radius: 12px;
  font-size: 16px;
}

.first_modal .form-check {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.first_modal .form-check input {
  width: 20px;
  height: 20px;
}

.info_card img {
  height: 60px;
  margin-bottom: 1rem;
}

.info_card h3 {
  font-size: 16px;
  font-weight: 500;
}

.card_upar {
  text-align: left;
}

.card_upar h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 1rem 0;

}

.first_modal h2 .header-logo {
  width: auto;
  display: inline-block;
  margin-left: 0;
  height: 35px;
}

.card_upar h4 {
  font-size: 18px;
  font-weight: 600;
}

.card_upar h5 {
  margin-top: 1.5rem;
}

.border-left {
  border-left: 2px solid #a3a3a3;

}

.modal_hedingimg {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;

}

.info-modal hr {
  margin: 0px;
  border-color: #333333;
}

.info-modal h6 {
  font-size: 16px;
}

.info-modal li {
  color: #C6C6C6;
}

.box_gray {
  padding: 15px;
  border-radius: 12px;
  margin-top: 15px !important;
}

.box_gray h6 {
  margin-bottom: 15px;
}

.box_gray li {
  margin-bottom: 10px;
}

.privacy_text {
  font-size: 10px !important;
  line-height: normal;
  margin-top: 15px;
}

.green_label {
  display: flex;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 1;
  justify-content: center;
}

.green_label p {
  display: flex;
}

.green_label p .span {
  padding: 1px 6px;
  line-height: 100%;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green_label div {
  display: flex;
}

.green_label div span {
  background: #00ff41;
  padding: 1px 6px;
  line-height: 100%;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle_card {
  border: 0 !important;
}

.buy_row .button_scroll {
  width: 100%;
}

.buy_row .button_scroll .c_scroll {
  padding-bottom: 1rem;
}

.custom_row {
  display: flex;
  gap: 15px;
}

.custom_row .custom_col {
  position: relative;
  width: 269px;
  min-width: 269px;
}

.inner-body .c_card.middle_card {
  padding: 0;
  height: auto;
  border-radius: 16px;
}

.bottom_middle {
  border-radius: 0 0 16px 16px !important;
  width: 100%;
  max-width: 100%;
  align-items: center;
  border-radius: 0 0 16px 16px;
  min-height: 40px;
  background: #191919;
  overflow: hidden;
  display: flex;
  font-size: 14px !important;
  justify-content: center;

}

.middle_card img {
  height: 88px;
  margin: 0 0 8px;
  align-self: center;
  z-index: 2;
  object-fit: contain;
}

.middle_card .left_half p {
  text-align: center;
  margin: 10px;
}

.inner-body .best_btn span {
  font-size: 12px;
}

.inner-body .best_btn {
  font-size: 14px;
  margin: 12px;
  height: 48px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 6px 15px;
  min-width: 64px;
  min-height: 36px;
  border-radius: 16px;
  line-height: normal;
}

.card_label {
  inset: 16px auto auto -8.5px;
  position: absolute;

}

.card_label p {
  inset: 5.5px auto auto 5%;
  position: absolute;
  z-index: 1;
  padding: 0 5px;
  color: #004010;
  font-size: 12px;
  line-height: 100%;
  font-weight: 700;
  width: 90%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.card_label img {
  width: 57px;
  height: 41px;
}

.right_carddiv {
  display: flex;
  border-radius: 10px;
  min-width: auto;
  padding: 10px;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: 1rem !important;
}

@media (min-width:992px) and (max-width:1300px) {
  .header_dropdown .MuiInputBase-formControl {
    width: 200px;
  }

  .add_header {
    width: 64px;
  }

  .header_dropdown {
    margin-left: 10px !important;
  }

  .header_search .MuiOutlinedInput-root {
    width: 280px;
  }
}

.congrat_page button {

  margin-top: 1rem !important;
}

.highlighted-header {
  position: sticky;
  top: 85px;
  background-color: #ffcc00;
  /* Highlight color */
  color: #000;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  z-index: 1000;
  /* Ensure it stays on top */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* Subtle shadow */
}

/* mobile css */

@media (max-width:767px) {
  .highlighted-header {
    position: sticky;
    top: 55px;
    margin-bottom: 10px;
    background-color: #ffcc00;
    /* Highlight color */
    color: #000;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    z-index: 1000;
    /* Ensure it stays on top */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    /* Subtle shadow */
  }

  .inner-body .long-card button {
    min-width: 90px;
    padding: 8px 11.5px;
  }

  .green_label div span {
    font-size: 10px;
  }

  .long-card .big_coin {
    top: -13px;
    left: -20px;
    width: 58px;
  }

  .long-card .small_coin {
    top: 35px;
    left: -8px;
    width: 30px;
  }

  .long-card .back_coin {
    left: 38px;
    width: 31px;
  }

  .inner-body .long-card {
    padding: 11px 6px 8px 27px;
  }
  .right_carddiv button{
    font-size: 10px;
  }
  .right_carddiv{
    padding: 6px;
    gap: 5px;
  }
  .custom_row .custom_col{
    width: 224px;
    min-width: 224px;
  }
  .buy_row .button_scroll .c_scroll {
    margin: 2rem;
}
}