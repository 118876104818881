
  .status-stepper {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 16px;
    color: #ccc;
  }
  
  .circle.completed {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;
  }
  
  .step-label {
    margin-top: 5px;
    font-size: 12px;
    color: white;
  }
  
  .step-connector {
    position: absolute;
    top: 30%; /* Aligns the line vertically with the circle */
    left: 100%; /* Starts from the edge of the current circle */
    width: 50px; /* Adjust based on the gap between steps */
    height: 2px;
    background-color: #4caf50; /* Same color as the completed circle */
    transform: translateY(-50%);
  }

  .step:nth-child(1) .step-connector {
    left: 90%;
  }
  
  .step:nth-child(2) .step-connector {
    left: 70%;
  }
  
  .step:nth-child(3) .step-connector {
    left: 100%;
  }
  

  .refreal_table{
    margin-top: 1rem!important;
  }
  
  @media screen and (max-width: 768px) {
    .main_referal_grid{
        padding-right: 0px!important;
        padding-left: 0px!important;
    }

    .refreal_table .MuiTableCell-root {
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
  }  